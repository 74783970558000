import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'
import MonumentCardPomnikOfiaryIIWSSulejowek from '../../../monuments/sulejowek/MonumentCardPomnikOfiaryIIWSSulejowek'
import MonumentCardPomnikPilsudskiegoSulejowek from '../../../monuments/sulejowek/MonumentCardPomnikPilsudskiegoSulejowek'

const SulejowekArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Sulejówek" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Sulejówek</Header1>
        <MonumentCardPomnikOfiaryIIWSSulejowek />
        <MonumentCardPomnikPilsudskiegoSulejowek />
        <MonumentCardAsset
          title={
            'Moja Szkoła w Sulejówku, Rocznik Mińskomazowiecki tom 25, 2017 r.'
          }
          author={'Ewa Karaś'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Sulej%C3%B3wek+Ewa+Kara%C5%9B+Moja+Szko%C5%82a+w+Sulej%C3%B3wku.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default SulejowekArchive
