import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikOfiaryIIWSSulejowek = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/sulejowek/pomnik-ofiar-iiws/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/sulejowek/pomnik-pamieci-ofiar-ii-wojny-swiatowej-sulejowek'
      }
      title={'Pomnik pamięci ofiar II wojny światowej - Sulejówek'}
    >
      Skwer Pamięci w Sulejówku jest miejscem upamiętnienia ofiar egzekucji z 15
      września 1939 roku.
    </MonumentCard>
  )
}

export default MonumentCardPomnikOfiaryIIWSSulejowek
