import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikPilsudskiegoSulejowek = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/sulejowek/pomnik-pilsudski/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/sulejowek/kopiec-i-pomnik-w-sulejowku'}
      title={'Kopiec i Pomnik w Sulejówku'}
    >
      Nieopodal willi „Milusin”, u zbiegu ulic Piłsudskiego i Paderewskiego w
      Sulejówku znajduje się Skwer Niepodległości.
    </MonumentCard>
  )
}

export default MonumentCardPomnikPilsudskiegoSulejowek
